<template>
  <div class="tabs-wrapper" data-testid="tabs">
    <div class="tabs-header">
      <ul>
        <li
          v-for="(tab, index) in tabData"
          :key="tab.title"
          :data-testid="`tab-${index + 1}`"
          :class="[
            tab.title === selectedTitle ? 'selected' : 'unselected',
            tab.disable ? 'disabled' : ''
          ]"
          class="flex items-end group"
          :tabindex="tab.disable ? -1 : 0"
          @keydown.enter.prevent="selectedTitle = tab.title"
          @keydown.space.prevent="selectedTitle = tab.title"
          @click="selectedTitle = tab.title"
        >
          <div>
            <span class="inline md:hidden">{{ tab.mobile || tab.title }}</span>
            <span class="hidden md:inline">{{ tab.title }}</span>
            <span v-if="tab.count != null" class="badge">{{ tab.count }}</span>
          </div>
        </li>
      </ul>
    </div>
    <div class="tabs-content">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
const slots = useSlots()

const tabData = computed(() =>
  slots.default?.().map((tab) => ({
    title: tab.props?.title,
    mobile: tab.props?.mobile,
    count: tab.props?.count,
    disable: tab.props?.disable
  }))
)

const selectedTitle = ref(tabData.value?.[0]?.title)
provide('selectedTitle', selectedTitle)

function changeTab(tabTitle: string) {
  selectedTitle.value = tabTitle
}

defineExpose({
  changeTab
})
</script>

<style scoped lang="postcss">
.tabs-wrapper {
  .tabs-header {
    ul {
      @apply flex flex-row space-x-2 md:space-x-4 border-b border-brand-grey-200 pl-6 md:pl-20;
    }

    li.selected {
      @apply bg-white text-black border-t-6 border-t-brand-azure-400 border-b-transparent;

      .badge {
        @apply bg-brand-azure-400 text-white group-hover:bg-brand-grey-400;
      }
    }

    li.disabled {
      @apply pointer-events-none;
    }

    li {
      @apply border-t py-3 px-5 select-none font-bold text-base text-gray-700 -mb-px border border-brand-grey-200 bg-brand-grey-100 cursor-pointer;

      .badge {
        @apply bg-brand-grey-400 text-white rounded-full px-2 py-1 ml-2;
      }
    }
  }

  .tabs-content {
    @apply p-3;
  }
}
</style>
